<template>
  <div>
    <b-form @submit="onSubmit">
      <b-form-group label="Email:" label-for="emailInput" description="We'll never share your email with anyone else.">
        <b-form-input id="emailInput" v-model="form.email" type="email" placeholder="Enter your email address." required></b-form-input>
      </b-form-group>
      
      <b-form-group label="Name:">
        <b-form-input v-model="form.name" placeholder="Enter your name." required></b-form-input>
      </b-form-group>
      
      <b-form-group label="Message:">
        <b-form-textarea v-model="form.message" rows="5" placeholder="Enter your message."></b-form-textarea>
      </b-form-group>

      <b-button type="submit" variant="primary" :disabled="isSubmitDisabled" :class="submitClass">Submit</b-button>
    </b-form>
    <label id="messageLabel" visible="isMessageLabelVisible" :class="messageLabelClass">{{message}}</label>
  </div>
</template>

<script>
import { addInformationRequest } from '../firestoreDb.js';

export default {
  name: 'Contact',
  data() {
    return {
      form: {
        email: '',
        name: '',
        message: ''
      },
      isSubmitDisabled: false,
      submitClass: '',
      isMessageLabelVisible: false,
      message: '',
      messageLabelClass: ''
    }
  },
  methods: {
    onSubmit(event) {
      this.isSubmitDisabled = true;
      event.preventDefault();

      // submit to firebase here
      const mailMessage = {
        to: ['sales@airedale.co'],
        message: {
          subject: `Sales information request: ${this.form.name} (${this.form.email})`,
          html: `New sales information request from ${this.form.name} (${this.form.email}):\n${this.form.message}`
        }
      }

      addInformationRequest(mailMessage).then(() => {
        this.submitClass = 'd-none';
        this.showMessage('Contact request successfully submitted! Someone from Airedale should contact you within twenty four hours.', false);
      }).catch((error) => {
        console.error(error);
        this.isSubmitDisabled = false;
        this.showMessage('Something went wrong submitting your contact request. Please try again.', true);
      });
    },
    showMessage(message, isError){
      this.message = message;
      this.messageLabelClass = isError ? 'text-danger' : 'text-success';
      this.isMessageLabelVisible = true;
    },
    hideMessage(){
      this.isMessageLabelVisible = false;
    }
  }
}
</script>

<style scoped>
.availability {
  margin-top: 10px;
}
</style>