// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, getDocs } from 'firebase/firestore/lite';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD1Py2jbVSGQI5oARAnKXK68WJKvMrP_Cs",
  authDomain: "airedale-software.firebaseapp.com",
  projectId: "airedale-software",
  storageBucket: "airedale-software.appspot.com",
  messagingSenderId: "239804285554",
  appId: "1:239804285554:web:2d19c14e5b038e8354f8eb",
  measurementId: "G-3LPT8JTT07"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const addInformationRequest = async function(mailMessage) {
    try {
        const docRef = await addDoc(collection(db, 'mail'), mailMessage);
        console.log("Added new mail message with ID: ", docRef.id);
    } catch (e) {
        console.error("Error adding mail message: ", e);
        throw e;
    }
};