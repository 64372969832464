<template>
  <div id="app">
    <Header id="header"></Header>
    <router-view id="routerOutput"/>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Footer from './components/Footer.vue'

export default {
  components: {
    Header,
    Footer
}
}
</script>
<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: rgba(247, 232, 164, 0.2);
}

#header {
  position: sticky;
  top: 0;
  z-index: 100;
}

</style>
