<template>
  <div>
    <b-container fluid class="pt-3">
      <b-row class="py-3 my-5 mx-1">
          <b-col lg class="my-auto">
            <div class="text-content">
              <h3 class="mb-4">Focused</h3>
              <p class="text-left">
                Concentrate on what you do best: running your business. The signal to noise ratio here is 100%
              </p>
              <p class="text-left">
                Airedale is focused on keeping you in the air. Avoid the chaff found in larger software packages.
                Maintenance forecasting (large image) and user defined warning limits (below) help you plan ahead.
              </p>
              <b-img src="@/assets/usageLimitWarningSettings.png" fluid thumbnail name="User Defined Warning Limits" alt="User Defined Warning Limits"></b-img>
            </div>
          </b-col>
          <b-col lg class="mt-3 mt-lg-0">
            <b-img src="@/assets/demoMaintenanceForecast.png" fluid thumbnail name="Maintenance Forecast" alt="Maintenance Forecast"></b-img>
          </b-col>
      </b-row>
      <b-row class="py-3 my-5 mx-1">
          <b-col lg class="d-none d-lg-block">
            <b-img src="@/assets/maintenanceForecastWithBubbleInfo.png" fluid thumbnail alt="Maintenance Forecast"></b-img>
          </b-col>
          <b-col lg class="my-auto">
            <div class="text-content">
              <h3 class="mb-4">Intuitive</h3>
              <p class="text-left">
                The tools you use should make your life easier. From publicly accessible help to in-application guidance, 
                Airedale was created from the ground up for zero training ease of use. Spend your time maintaining aircraft, 
                not slogging through software tutorials.
              </p>
            </div>
          </b-col>
          <b-col lg class="d-lg-none">
            <b-img src="@/assets/maintenanceForecastWithBubbleInfo.png" fluid thumbnail alt="Maintenance Forecast"></b-img>
          </b-col>
      </b-row>
      <b-row class="py-3 my-5 mx-1">
          <b-col lg class="my-auto">
            <div class="text-content">
              <h3 class="mb-4">Customizable</h3>
              <p class="text-left">
                Customize requirements. Create recurring maintenance requirements due based on hours, cycles, landings, days, or years. 
                Set up one-time requirements to record non-periodic maintenance. Requirements can apply to individual components or entire categories. 
                It’s up to you.
              </p>
            </div>
          </b-col>
          <b-col lg>
            <b-img src="@/assets/statusBoardWithRequirementsBlowout.png" fluid thumbnail alt="Status board and associated requirements"></b-img>
          </b-col>
      </b-row>
      <b-row class="py-3 my-5 mx-1">
          <b-col lg class="d-none d-lg-block">
            <b-img src="@/assets/sideMenuWithNestedComponentsExpanded.png" fluid thumbnail alt="Expanded side menu showing nested components."></b-img>
          </b-col>
          <b-col lg class="my-auto">
            <div class="text-content">              
              <h3 class="mb-4">Flexible</h3>
              <p class="text-left">
                Everything tracked in Airedale is a component. Keep it simple and track just aircraft. Nest components to track propellers on engines on aircraft,
                as shown in the associated side menu image. Each component has its own log to maintain integrity when moving whole assemblies from one component 
                to another or into storage. Keep track of buildings, licenses, leases, and insurance renewals. It's your system; build it your way.
              </p>
            </div>
          </b-col>
          <b-col lg class="d-lg-none">
            <b-img src="@/assets/sideMenuWithNestedComponentsExpanded.png" fluid thumbnail alt="Expanded side menu showing nested components."></b-img>
          </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

export default {
  name: "Home"
}

</script>

<style scoped>
.text-content {
  padding-left: 5em;
  padding-right: 5em;
}
</style>
