<template>
    <div>
        <b-navbar toggleable="md" class="header-background">
            <b-navbar-toggle id="nav-toggle" target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <b-nav-item to="/">Home</b-nav-item>
                    <b-nav-item to="/about">About</b-nav-item>
                    <b-nav-item v-b-modal.contact-modal href="#">Contact</b-nav-item>
                    <b-nav-item href="https://airedale.app/" target="_blank" class="d-md-none">Login</b-nav-item>
                </b-navbar-nav>
            </b-collapse>

            <b-navbar-brand id="nav-brand" class="mx-auto w-100 d-none d-md-block" href="#">
                <img class="logo" alt="Airedale, by Two Brothers Software" src="../assets/airedale-logo.svg">
                <div class="subtitle-text">Maintenance tracking made affordable.</div>
            </b-navbar-brand>

            <b-navbar-brand id="nav-brand" class="mx-auto d-md-none" href="#">
                <img class="logo" alt="Airedale, by Two Brothers Software" src="../assets/airedale-logo.svg">
                <div class="subtitle-text">Maintenance tracking made affordable.</div>
            </b-navbar-brand>

            <b-link href="https://airedale.app/" target="_blank" class="btn btn-outline-dark d-none d-md-block">Login</b-link>
        </b-navbar>
        <b-modal id="contact-modal" title="Two Brothers Software, Inc." title-tag="h4" hide-footer
            header-class="modal-header-background" body-class="modal-body-background">
            <div class="text-center">
                <Contact></Contact>
            </div>
        </b-modal>
    </div>
</template>

<script>
import Contact from '../views/Contact.vue';

export default {
    name: "Header",
    components: { Contact }
}
</script>

<style>
    .logo {
      max-height: 40px;
    }

    .header-background {
        background-color: rgb(213, 201, 223);
        padding-top: 15px;
    }

    .nav-item {
        font-weight: bold;
    }

    .subtitle-text {
        font-size: smaller;
    }

    .modal-header-background {
        background-color: rgb(213, 201, 223);
    }

    .modal-body-background {
        background-color: rgba(247, 232, 164, 0.2);
    }
</style>