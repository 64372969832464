<template>
    <div class="footer-background">
        <div>
            Two Brothers Software, Inc.<span class="align-top" style="font-size: x-small;">&copy;</span>
        </div>
        <div>
            <b-link href="tel:+1250-881-0584">+1 250.881.0584</b-link>
        </div>
        <div>
            <b-link href="mailto:sales@airedale.co">sales@airedale.co</b-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style scoped>
    .footer-background {
        background-color: rgb(213, 201, 223);
        padding-bottom: 15px;
        padding-top: 15px;
    }
</style>